/* Light Theme */
.light-theme .ant-layout-header {
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
}
.light-theme .ant-layout-footer {
  background: #fff;
  color: #999;
}
.light-theme.ant-layout {
  background: #fff;
}
.light-theme .logo {
  margin-top: 16px;
}
.light-theme .logo h3.ant-typography {
  color: #000 !important;
}
.light-theme .logo-slogan path {
  fill: #333;
}
.light-theme .site-layout-content {
  padding: 8px 0px;
}
.light-theme .page-title {
  padding: 0px 0px 8px 0px;
  margin: 0px 0px 8px 0px;
  border: 0px none;
}
