/* Text Align */
.ant-text-center {
  text-align: center;
}
.ant-text-left {
  text-align: left;
}
.ant-text-right {
  text-align: right;
}

/* Text Transform */
.ant-text-capitalize {
  text-transform: capitalize;
}

/* Text Colors */
.ant-color-red {
  color: #ff4d4f;
}
.ant-color-green {
  color: #52c41a;
}
.ant-color-orange {
  color: #faad14;
}
.ant-color-purple {
  color: #b604d0;
}

/* Button Reset */
.ant-btn-reset {
  border: 0px none;
  background: none;
  padding: 0px;
  margin: 0px;
  width: auto !important;
  height: auto;
  line-height: normal;
}
.ant-btn-reset:hover {
  background: none;
}

/* Border Classes */
.ant-border {
  border: solid 1px #f0f0f0;
}
.ant-border-l {
  border-left: solid 1px #f0f0f0;
}
.ant-border-r {
  border-right: solid 1px #f0f0f0;
}
.ant-border-t {
  border-top: solid 1px #f0f0f0;
}
.ant-border-b {
  border-bottom: solid 1px #f0f0f0;
}
.ant-border-none {
  border: none 0px;
}

/* Margin Classes */
.ant-m-0 {
  margin: 0px;
}
.ant-m-1 {
  margin: 4px;
}
.ant-m-2 {
  margin: 8px;
}
.ant-m-3 {
  margin: 12px;
}
.ant-m-4 {
  margin: 16px;
}
.ant-m-5 {
  margin: 20px;
}
.ant-m-6 {
  margin: 24px;
}
.ant-m-7 {
  margin: 24px;
}

/* Padding Classes */
.ant-p-0 {
  padding: 0px;
}
.ant-p-1 {
  padding: 4px;
}
.ant-p-2 {
  padding: 8px;
}
.ant-p-3 {
  padding: 12px;
}
.ant-p-4 {
  padding: 16px;
}
.ant-p-5 {
  padding: 20px;
}
.ant-p-6 {
  padding: 24px;
}
.ant-p-7 {
  padding: 24px;
}
