body {
  margin: 0px;
}
.logo {
  width: 134px;
  height: 28px;
  margin: 10px 24px 0px 0px;
  float: left;
}
.site-layout-content {
  background: #fff;
  padding: 16px 24px 16px 24px;
  min-height: 280px;
}
.ant-layout-content {
  padding: 24px 50px;
  min-height: calc(100vh - 120px) !important;
}
.ant-layout-footer {
  text-align: center;
  padding: 16px 0px !important;
}
.ant-table-thead > tr > th {
  font-weight: 600 !important;
}

.list-filters {
  margin: 0px 0px 16px 0px;
}

.list-filters .ant-select {
  width: 14%;
}
.list-filters .ant-input-group-wrapper {
  width: 30%;
}
.filters-checkbox .ant-checkbox-group-item {
  display: flex;
  margin: 8px 0px 0px 0px;
  align-items: center;
  width: 100%;
}
.export-checkbox {
  display: flex;
  flex-flow: row wrap;
}
.export-checkbox .ant-checkbox-group-item {
  display: flex;
  margin: 8px 0px 0px 0px;
  align-items: center;
  flex: 0 0 49%;
  max-width: 49%;
}

.list-loader {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.page-title {
  border-bottom: solid 1px #f0f0f0;
  padding: 0px 0px 8px 0px;
  margin: 0px 0px 16px 0px;
}

.page-title h4 {
  margin-top: 0px;
}

.page-title .ant-btn {
  float: right;
}
.page-title-detail .ant-btn {
  float: left;
  margin-right: 8px;
}
.page-title-custom-btn {
  float: right;
  position: relative;
  margin-top: -64px;
}

.customer-tabs.ant-tabs-top > .ant-tabs-nav {
  padding-left: 16.66666667%;
  margin-bottom: 28px;
}
.customer-tabs .dynamic-delete-button {
  position: absolute;
  left: 60%;
  top: 8px;
}
.customer-tabs .gst-info .dynamic-delete-button {
  left: 78%;
}
.customer-tabs.ant-tabs-top > .ant-tabs-nav {
  padding-left: 0px;
}

.customer-tabs .ant-space {
  position: relative;
  display: block;
}
.customer-tabs .ant-space .ant-space-item {
  margin-right: 0px !important;
}

.ant-space {
  position: relative;
  display: block;
}

.poc-add-form .dynamic-delete-button {
  position: absolute;
  left: 94%;
  top: 8px;
}

.poc-add-form .ant-space .ant-space-item {
  margin-right: 0px !important;
}

.customer-details
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-left: 42px;
  padding-top: 0px;
}

.customer-details .customer-logo {
  background: #fff;
  border: solid 1px #f0f0f0;
  width: 100%;
  object-fit: contain;
  padding: 8px;
}

.pointofcontact_add .is_phone_verified,
.pointofcontact_add .is_email_verified {
  position: absolute;
  right: 4px;
  top: -1px;
}

.multi-company {
  border-top: solid 1px #f0f0f0;
  padding-top: 24px;
}

.customer-tabs .multi-company button.dynamic-delete-button {
  top: 24px !important;
}

.customer-details .multi-company {
  padding-top: 16px;
  margin-top: 24px;
}

.inventory-nav {
  margin-bottom: 16px;
}

.inventory-nav-switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.inventory-nav-switch label {
  margin-left: 8px;
}

.base-inventory-list {
  padding-left: 24px;
  margin: 12px 0px 42px 0px;
}

.status-record-read {
  color: #999;
}

.status-record-unread {
  font-weight: 600;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}
.leaflet-default-icon-path {
  background-image: url(https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png) !important;
}
.leaflet-map-popup {
  min-width: 160px;
  font-size: 0.8rem;
}
.leaflet-map-popup div {
  margin-top: 12px;
}

.disabled-row {
  cursor: not-allowed;
  color: rgb(180, 180, 180);
}

.hide-filtered-row {
  display: none;
}

@media (max-width: 575px) {
  .ant-layout-content {
    padding: 16px;
  }
  /* .ant-menu-dark.ant-menu-horizontal {
    display: inline;
    width: 16px;
  }
  .ant-menu-dark .ant-menu-submenu-selected {
    background: none !important;
  }
  .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    font-size: 24px;
  } */
  .site-layout-content {
    padding: 16px;
  }
  .page-title button.ant-btn span:not(.anticon):not(.ant-btn-icon) {
    display: none;
  }
}

.ant-menu-dark .ant-menu-submenu-selected {
  color: #fff;
  background-color: #1890ff;
}

.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #fff;
}

.custom-images-display {
  margin: -8px;
}
.custom-images-display .ant-image {
  padding: 8px;
  margin-bottom: 16px;
}
.custom-images-display .ant-image-img {
  object-fit: contain;
  border: solid 1px #f0f0f0;
  cursor: pointer;
  padding: 8px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 48px;
  width: 48px;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 28px;
  width: 28px;
}

.advanced-filters .ant-space .ant-space-item {
  margin-right: 0px !important;
}
.advanced-filters .dynamic-delete-button {
  position: absolute;
  top: 10px;
  right: -12px;
}
.advanced-filters .dynamic-add-button {
  position: absolute;
  top: 10px;
  right: -36px;
}

.custom-btn-approve {
  background: #52c41a;
  border-color: #52c41a;
  color: #fff;
  margin-right: 16px;
}
.custom-btn-approve:hover,
.custom-btn-approve:focus {
  background: #70e337;
  border-color: #70e337;
}
.custom-btn-reject {
  background: #ff4d4f;
  border-color: #ff4d4f;
  color: #fff;
  margin-right: 16px;
}
.custom-btn-reject:hover,
.custom-btn-reject:focus {
  background: #ff7b7d;
  border-color: #ff7b7d;
}

.campaign-notifications-list {
  margin-bottom: 8px;
}
.campaign-notifications-list .ant-form-item {
  margin-bottom: 8px;
}
.operation-nav {
  position: relative;
  float: right;
  top: -62px;
  margin-bottom: -62px;
}
.operation-nav .ant-switch {
  margin-right: 8px;
}
.operation-nav .ant-btn {
  margin-left: 16px;
}

.operation-tabs {
  margin-bottom: 16px;
  height: 32px;
}

.imp-inv-error {
  background: #fff2e8;
}

.fixed-actions {
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 1000;
  border-radius: 24px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding-left: 36px;
}

.fixed-actions b {
  background-color: #ffffff;
  color: #1890ff;
  width: 27px;
  height: 27px;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  margin-right: -4px;
  line-height: 24px;
}

.row-bg-orange {
  background: linear-gradient(to left, #fff 0%, #fff 30%, #fff1cf 100%);
}
.row-bg-green {
  background: linear-gradient(to left, #fff 0%, #fff 30%, #daffcf 100%);
}
.row-bg-yellow {
  background: linear-gradient(to left, #fff 0%, #fff 30%, #fbff84 100%);
}
.row-bg-redgray {
  background: linear-gradient(to left, #fff 0%, #fff 30%, #fff4f4 100%);
}
.row-bg-red {
  background: linear-gradient(to left, #fff 0%, #fff 30%, #ffcfcf 100%);
}

.input-reset-icon {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: calc(50% - 14px);
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 28px;
  background: #fff;
}

.input-reset-icon:hover {
  background: rgb(235, 235, 235);
}

.add-edit-manual-invoice .dynamic-delete-button {
  position: absolute;
  top: 10px;
  right: -12px;
}
.add-edit-manual-invoice .dynamic-add-button {
  position: absolute;
  top: 10px;
  right: -36px;
}

.edit-configuration .dynamic-delete-button {
  position: absolute;
  right: 24px;
  top: 8px;
}
.edit-configuration .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  padding: 16px;
  height: 124px;
}

.photobucket {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -8px;
}
.photobucket-col {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 8px;
  position: relative;
}
.photobucket-btn {
  padding: 0px;
  margin: 0px;
  position: relative;
  border: 0px none;
  width: 100%;
  height: auto;
  display: block;
  font-size: 0px;
}
.photobucket-image {
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 128px;
  object-fit: cover;
  min-height: 128px;
}
.photobucket-image-name {
  background: rgba(0, 0, 0, 0.1);
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}
.photobucket-image-type {
  position: absolute;
  top: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.8);
  padding: 4px 8px;
  color: #fff;
  font-size: 12px;
}
.photobucket-checkbox {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
  font-size: 0px;
  line-height: 0px;
  padding: 6px 6px 5px 6px;
}
.menu-sup {
  background: orange;
  border-radius: 4px;
  padding: 0px 2px;
  margin: 0px -22px 0px 4px;
}

.menu-toggle {
  position: absolute;
  top: 20px;
  left: 188px;
  cursor: pointer;
}

.photo-download-btn {
  position: absolute;
  top: 12px;
  right: 48px;
  padding: 0px;
  margin: 0px;
  width: 34px;
}

.customer-tabs.customer-tabs-new .ant-tabs-tab-disabled {
  display: none;
}

@media (max-width: 575px) {
  .ant-layout-header {
    height: 48px;
    padding: 0px 12px;
  }
  .logo {
    width: 116px;
    margin: 6px 24px 0px 0px;
  }
  .ant-layout-content {
    padding: 0px;
  }
  .page-title .ant-typography {
    font-size: 16px;
  }
  .page-title .ant-btn {
    padding: 0px 8px;
    margin-top: -6px;
  }
  .list-filters .ant-input-group .ant-select {
    width: 34%;
    font-size: 13px;
  }
  .list-filters .ant-input-group .ant-input-group-wrapper {
    width: 54%;
  }
  .list-filters .ant-input-group .ant-btn {
    padding: 0px 8px;
  }
  .list-filters .ant-select-dropdown .ant-select-item {
    font-size: 12px;
  }
  .ant-table-thead .ant-table-cell {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    white-space: nowrap;
    font-size: 13px;
  }
  .ant-table-tbody .ant-table-cell {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    font-size: 12px;
  }
  .ant-table-tbody td:last-child {
    white-space: nowrap;
  }
  .ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ant-table .ant-btn-link {
    font-size: 12px;
  }
  .ant-badge {
    font-size: 12px;
  }
  .mobile-hide {
    display: none;
  }
  button.icon-btn {
    padding: 0px 8px;
  }
  button.icon-btn span:not(.anticon):not(.ant-btn-icon) {
    display: none;
  }
  .ant-radio-group-solid {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }
  .ant-radio-group-solid label {
    font-size: 12px;
    padding: 0px 8px;
  }
  .ant-form .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form .ant-form-item .ant-form-item-label {
    padding-bottom: 0px;
  }
  .ant-form .ant-form-item .ant-form-item-label > label {
    font-size: 12px;
  }
  .model-btns .ant-form-item-control {
    margin: 8px 0px 0px 0px;
    text-align: center;
  }
  .ant-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .ant-drawer .ant-drawer-header {
    padding: 16px;
  }
  .ant-drawer .ant-drawer-body {
    padding: 16px;
  }
  .ant-modal .ant-modal-header {
    padding: 16px;
  }
  .ant-modal .ant-modal-body {
    padding: 16px;
  }
  .title-btns {
    top: -106px !important;
    right: 0px !important;
  }
  .title-btns-2 {
    top: -106px !important;
  }
  .ant-switch-small + label {
    font-size: 12px;
  }
  .without-label {
    margin-top: 32px;
  }
  .without-label-text {
    margin-top: 36px !important;
  }
  .ant-radio-group span {
    font-size: 12px;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    font-size: 12px;
    margin-right: 24px;
  }
  .fields-list-btn .ant-form-item-control {
    margin: 0px;
  }

  .company-type .ant-form-item-control {
    margin: 0px;
  }
  .customer-tabs.ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 12px;
  }
  .customer-tabs .gst-info .dynamic-delete-button,
  .customer-tabs .dynamic-delete-button {
    left: auto;
    right: 8px;
  }

  .inventory-nav {
    position: relative;
    padding: 0px 12px 0px 0px;
    margin: 0px 0px 16px 0px;
    /* display: none; */
  }
  .inventory-nav .inventory-nav-tabs {
    /* display: none; */
    margin-bottom: 12px;
  }
  .inventory-nav .inventory-nav-switch {
    /* flex: auto;
    max-width: none; */
    justify-content: flex-start;
  }
  .inventory-ta-switch {
    top: -60px !important;
    right: 94px;
  }
  .inventory-ta-switch-lbl {
    position: absolute;
    left: -16px;
    top: 20px;
    font-size: 10px !important;
  }
  .inventory-del-switch {
    top: -98px !important;
    right: 28px !important;
  }
  .add-base-inv-btn {
    position: absolute;
    top: -64px;
    right: 0px;
  }
  .add-group-inv-btn {
    position: absolute;
    top: -64px;
    right: 0px;
  }
  .lat-long-icon {
    margin: -40px 0px 0px auto;
    position: relative !important;
  }
  .lat-long-input {
    width: 88% !important;
  }
  .invoicelist-btns {
    margin-top: 0px;
    top: -112px;
  }
  .sales-dtl-btns {
    margin-top: auto;
    margin-bottom: -36px;
  }
  .sales-add-btns {
    margin-top: auto;
    margin-bottom: -36px;
  }
  .customer-details .ant-collapse h4.ant-typography {
    font-size: 16px;
  }
  .customer-details
    .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-left: 16px;
  }
  .advanced-filters-mobile > div.ant-row {
    margin-bottom: 4px !important;
  }
  .advanced-filters-mobile > div.ant-row > div.ant-col-4 {
    margin-bottom: 4px !important;
    font-size: 12px;
  }
  .advanced-filters .dynamic-add-button {
    position: absolute;
    top: -20px;
    right: -6px;
  }
  .advanced-filters .dynamic-delete-button {
    right: -6px;
  }
  .advanced-filters .ant-space-item > div.ant-row {
    margin-bottom: 0px !important;
  }
  .invoice-box {
    border: solid 1px #f0f0f0;
    margin: 0px 0px 8px 0px !important;
    padding: 8px 12px 8px 12px;
  }
  .invoice-box > div > div {
    margin: 0px 0px 4px 0px !important;
  }
  .invoice-box-right > div > div b {
    float: right;
  }
  .invoice-box-last {
    margin-bottom: 0px;
  }
  .invoice-bulkedit-title {
    padding: 8px 8px 0px 8px !important;
    margin: 0px !important;
    justify-content: flex-start !important;
  }
  .invoice-bulkedit-form {
    padding: 0px 8px 8px 8px !important;
  }
  .invoice-add-form {
    margin-top: 48px;
  }
  .photo-gallery-min-height {
    min-height: auto !important;
  }
  .plan-dtl-boxes > div.ant-col {
    margin-bottom: 16px;
  }
  .plan-dtl-boxes > div.ant-col:last-child {
    margin-bottom: 0px;
  }
  .plan-dtn-title-btn {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }
  .plan-dtn-title-btn .ant-dropdown-trigger {
    float: right;
  }
  .campaign-operation-pop-form {
    margin-bottom: 12px;
  }
  .plan-inv-list-actions {
    margin: 0px 0px 16px 0px !important;
    width: 100%;
  }
  .plan-inv-list-actions .ant-dropdown-trigger {
    float: right;
  }

  form#normal_login {
    width: 92%;
    padding: 24px 24px 16px 24px;
  }
  .web-menu {
    display: none !important;
  }
  .mobile-menu {
    display: block !important;
    float: right;
    top: 8px;
    background: none;
    border: 0px none;
    color: #fff;
  }
  .mobile-menu-dropdown .ant-dropdown-menu-submenu {
    height: 42px;
    line-height: 42px;
  }
  .mobile-menu-dropdown .ant-dropdown-menu-item {
    line-height: 32px;
  }
  .mobile-menu-dropdown .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(256, 256, 256, 0.2);
  }
  .ant-picker-panels {
    flex-direction: column;
  }
  .plan-campaign-inv .page-title .ant-typography {
    padding-right: 42px;
  }
  .master-import-btns {
    margin-top: -60px !important;
  }
  .mobile-public-dropdown {
    margin: 0px 0px 16px 0px !important;
  }
  .title-btns-photobucket {
    top: -60px !important;
  }
  .photobucket-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu-toggle {
    top: 14px;
    left: 136px;
  }
  .title-btns-purchase {
    right: 28px !important;
    top: -102px !important;
  }
  .ant-modal-content {
    padding: 0px !important;
  }
  .ant-modal-header {
    border-bottom: 1px solid #f0f0f0 !important;
  }

  @supports (-webkit-touch-callout: none) {
    .row-bg-orange {
      background: #fff1cf;
    }
    .row-bg-green {
      background: #daffcf;
    }
    .row-bg-yellow {
      background: #fbff84;
    }
    .row-bg-redgray {
      background: #fff4f4;
    }
    .row-bg-red {
      background: #ffcfcf;
    }
  }
}

@media print {
  body {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 12px;
    color: #000;
    font-family: "Calibri";
    background: none !important;
  }
  body * {
    color: #000;
    font-family: "Calibri";
    background: none !important;
  }
  header,
  footer {
    display: none;
  }
  .ant-layout,
  .ant-layout-content,
  .site-layout-content {
    padding: 0px !important;
    margin: 0px !important;
    width: 100% !important;
  }
  .print-hide {
    display: none !important;
  }
  .invoice-print .page-title {
    display: none !important;
  }
  .ant-table {
    font-size: 12px;
    font-family: "Calibri";
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-top: solid 1px #999;
    font-size: 12px;
  }
  .ant-table-thead > tr > th {
    white-space: nowrap;
    background: #fafafa !important;
    font-size: 12px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: solid 1px #999 !important;
    font-size: 12px;
  }
  .invoice-print-view .ant-table colgroup col {
    min-width: auto !important;
    width: auto !important;
  }
  .invoice-print-view .ant-table colgroup col:first-child {
    min-width: 52% !important;
    width: 52% !important;
  }
  .invoice-print-view {
    display: block !important;
  }
  .invoice-print-view .invoice-print-title {
    background: #f7f7f7 !important;
    background-color: #f7f7f7 !important;
    border: solid 1px #f7f7f7;
    padding: 5px 10px;
  }
  .invoice-print-view h2 {
    font-size: 20px;
    padding: 0px;
    margin: 0px;
    font-weight: bold;
  }
  .invoice-print-view h4 {
    font-size: 11px;
    padding: 0px;
    margin: -4px 0px 0px 0px;
    font-weight: normal;
  }
  .invoice-print-view .ant-table-title {
    display: none;
  }
  .invoice-print-logo {
    float: right;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
  }
  .invoice-print-logo img {
    max-height: 32px;
  }
  .invoice-print-view .ant-avatar {
    display: none !important;
  }
  .ant-dropdown {
    display: none !important;
  }
  .ant-drawer {
    display: none !important;
  }
}

body > iframe[style*="2147483647"] {
  display: none;
}

/* Dashboard */
.link-hdr {
  cursor: pointer;
}
.link-hdr:hover {
  text-decoration: underline;
  color: #1890ff;
}
.link-value {
  cursor: pointer;
}
.link-value:hover {
  text-decoration: underline;
  color: #1890ff;
}

.plan-dtl-boxes h5 {
  margin-top: 8px;
}
.plan-dtl-boxes .ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
}
.plan-dtl-boxes .ant-descriptions-item {
  padding-bottom: 16px !important;
}
.list-filters .ant-input-group .ant-btn {
  border-radius: 0px 6px 6px 0px !important;
}
.list-filters .ant-input-group .ant-input-outlined {
  border-radius: 0px !important;
}
.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-btn-link {
  color: #1890ff;
}

.ant-menu a {
  transition: color 0.1s !important;
}
.ant-menu a:hover {
  color: #fff !important;
  transition: color 0.1s !important;
}
.ant-menu-title-content {
  transition: color 0.1s !important;
}
.ant-menu-title-content:hover {
  color: #fff !important;
  transition: color 0.1s !important;
}

.list-filters-dashboard .ant-input-group .ant-input-outlined {
  border-radius: 6px 0px 0px 6px !important;
}
.list-filters-dashboard .ant-input-group-wrapper {
  width: 100%;
}

.list-filters-dashboard {
  margin-top: -58px;
  width: 320px;
  float: right;
}

.po_settings .ant-form-item {
  margin-bottom: 12px;
}
